<template>
  <div class="customer-search-container">
    <a-range-picker
      class="search_item"
      format="YYYY-MM-DD"
      :placeholder="['Start', 'End']"
      @change="ChangeDate"
      :value="rangePickerDate"
      :allowClear="false"
      :ranges="{
        今日: [initDate(0, 0).start, initDate(0, 0).end],
        昨日: [initDate(1, 1).start, initDate(1, 1).end],
        最近一周: [initDate(6, 0).start, initDate(6, 0).end],
        最近一个月: [initDate(30, 0).start, initDate(30, 0).end]
      }"
      :disabledDate="disabledDate"
    >
      <!-- <a-icon slot="suffixIcon" type="calendar" /> -->
    </a-range-picker>

    <m-select-more
      class="search_item"
      v-model="searchquery.advertiserIdList"
      :allData="advertiserList"
      :searchById="true"
      :hasIcon="true"
      :showId="false"
      width="450px"
      label="广告主"
      @change="changeAdvertiserId"
    />
    <m-select-more
      class="search_item"
      v-model="searchquery.planIdList"
      :allData="adPlanList"
      :searchById="true"
      :hasIcon="true"
      :showId="false"
      width="450px"
      label="广告计划"
      @change="changeAdPlan"
    />
    <m-select-more
      class="search_item"
      v-model="searchquery.planGroupIdList"
      :allData="adGroupList"
      :searchById="true"
      :hasIcon="true"
      :showId="false"
      width="450px"
      label="广告组"
      @change="changeGroupId"
    />
    <m-select-more
      class="search_item"
      v-model="searchquery.adIdList"
      :allData="adList"
      :searchById="true"
      :hasIcon="true"
      :showId="false"
      width="450px"
      label="广告"
      @change="changeAdPlanId"
    />
    <m-select-more
      class="search_item"
      v-model="searchquery.appIdList"
      :allData="appList"
      :searchById="true"
      :hasIcon="true"
      :showId="true"
      width="450px"
      label="应用"
      @change="changeAppIds"
    />
    <m-select-more
      class="search_item"
      v-model="searchquery.placeIdList"
      :allData="placeList"
      :searchById="true"
      :placement="placement"
      :hasIcon="true"
      :showId="true"
      width="450px"
      label="广告位"
      @change="changePlaceIds"
    />
    <m-select-more
      class="search_item"
      v-model="searchquery.groupIdList"
      :allData="groupList"
      :searchById="true"
      :hasIcon="true"
      :showId="true"
      width="450px"
      label="流量分组"
      @change="handleAutoSearch"
      v-if="role !== 'ADVERTISERS' && role !== 'AGENT_ADVERTISERS' && role !== 'AGENT'"
    />
    <m-select-more
      class="search_item"
      v-model="searchquery.positionIdList"
      :allData="positionList"
      :searchById="false"
      :hasIcon="false"
      :showId="false"
      label="广告样式"
      :showSelectedPart="true"
      width="450px"
      :canSelectAll="true"
      @change="handleAutoSearch"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import mixDate from '@/mixins/initDate'
import mixGetList from '@/mixins/getList'
// import { getAdGroupList } from '@/api/advertisementStep'
import { getadList, getAdPlanList, getAdGroupList } from '@/api/reportdatas'
import { advertiserList, getAdplanByAdvertiserIds } from '@/api/advertisement'
import { getDuration } from '@/utils/duration'

export default {
  mixins: [mixDate, mixGetList],
  data () {
    return {
      searchquery: {
        dateEnd: '',
        dateStart: '',
        appIdList: [],
        placeIdList: [],
        groupIdList: [],
        sourceIdList: [],
        advertiserIdList: [],
        planIdList: [],
        planGroupIdList: [],
        positionIdList: []
      },
      advertiserList: [],
      adPlanList: [],
      adGroupList: [],
      adList: [],
      placement: 'bottomRight'
    }
  },
  computed: {
    ...mapState({
      role: (state) => state.user.roles[0],
      title: (state) => state.autoweb.title
    })
  },
  async created () {
    // 初始化时间
    const { endDate, startDate, end, start } = this.initDate(6, 0)
    this.rangePickerDate = [start, end]
    this.searchquery.dateEnd = endDate
    this.searchquery.dateStart = startDate
    await this.getAppList()
    await this.getPlaceList()
    await this.getGroupList()
    await this.getSourceList()
    await this.getAdvertiserList()
    await this.getPositionList()
    await this.getAdPlanList()
    this.getAdGroupList()
    this.getadList()
    if (this.$route && this.$route.params && this.$route.query.id) {
      setTimeout(() => {
        this.searchquery.planIdList.push('' + this.$route.query.id)
        this.$emit('handleAutoSearch', this.searchquery)
      }, 0)
    } else {
      // 进入时搜索
      this.$emit('handleAutoSearch', this.searchquery)
    }
  },
  methods: {
    // 广告
    async getadList () {
      const resp = await getadList(this.searchquery.planGroupIdList)
      this.adList = resp.data || []
    },
    // 广告组
    async getAdGroupList () {
      const resp = await getAdGroupList(this.searchquery.planIdList)
      this.adGroupList = resp.data || []
    },
    // 不可选时间
    disabledDate (current) {
      // 默认大于今天的时间 小于2021.9
      return (current && current < this.initDate(getDuration(), 0).start) || current > this.initDate(0, 0).end
    },
    // 时间修改
    ChangeDate (date, dateString) {
      this.searchquery = {
        ...this.searchquery,
        dateEnd: dateString[1],
        dateStart: dateString[0]
      }
      this.rangePickerDate = date
      this.handleAutoSearch()
    },
    // 应用改变查广告位
    async changeAppIds (ids) {
      if (ids.length === 0) {
        // app无值 通过全部appList查place列表
        await this.getPlaceListByAppIds(this.appList.map((item) => item.id))
      } else {
        // app选了 通过选了的app查place列表
        await this.getPlaceListByAppIds(ids)
      }
      this.changePlaceIds(this.searchquery.placeIdList)
    },
    // 广告位改变查流量分组
    async changePlaceIds (ids) {
      if (ids.length === 0) {
        // 广告位无值，通过全部placelist查询group列表
        // await this.groupListByAdplace(this.placeList.map((item) => item.id))
        await this.getGroupList()
        this.getSourceList(this.placeList.map((item) => item.id))
      } else {
        // 广告位选了，通过选中的place查询group列表
        await this.groupListByAdplace(ids)
        this.getSourceListByAdplace(ids)
      }
      this.handleAutoSearch()
    },
    // 搜索
    handleAutoSearch () {
      this.$emit('handleAutoSearch', this.searchquery)
    },
    // 广告主列表
    getAdvertiserList () {
      advertiserList().then((resp) => {
        this.advertiserList = (resp.data || []).map((item) => ({
          name: item.username,
          id: item.id
        }))
      })
    },
    // 广告计划列表
    getAdPlanList () {
      getAdPlanList(this.searchquery.advertiserIdList).then((resp) => {
        this.adPlanList = resp.data || []
      })
    },
    // 修改广告主选择项回调
    async changeAdvertiserId (ids) {
      await this.getAdPlanList()
      this.handleAutoSearch()
    },
    // 修改广告计划选择项回调
    changeAdPlan () {
      this.getAdGroupList()
      this.handleAutoSearch()
    },
    // 改变广告组选项
    changeGroupId (id) {
      this.getadList()
      this.handleAutoSearch()
    },
    changeAdPlanId (ids) {
      this.handleAutoSearch()
    },
    // 通过广告主查询广告计划
    getAdplanByAdvertiserId () {
      getAdplanByAdvertiserIds({ userIdList: this.searchquery.advertiserIdList }).then((resp) => {
        this.adPlanList = resp.data || []
      })
    }
  }
}
</script>

<style lang="less" scoped>
.customer-search-container {
  background: #fff;
  padding: 10px;
  border-radius: 5px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  gap: 10px;
}
</style>
